<template>
  <div>
    <div class="row">
      <div class="col">
        <h3>Live Broadcast</h3>
        <div class="p-2">
          <filter-bar @update-list="updateBroadcastList"></filter-bar>
        </div>
        <vuetable data-path="broadcast"
                  ref="vuetable"
                  @vuetable:pagination-data="onPaginationData"
                  @vuetable:loading="showLoader"
                  @vuetable:loaded="hideLoader"
                  pagination-path="pagination"
                  :api-url="apiUrl"
                  :fields="fields"
                  :http-options="httpOptions"
                  :css="css.table"
                  :per-page="10"
                  :append-params="moreParams"
                  :query-params="{
                    sort: 'sort',
                    page: 'page',
                    perPage: 'perPage'
                  }"
                  detail-row-component="live-broadcast-detail"
                  track-by="live_broadcast_id"
                  @vuetable:cell-clicked="onCellClicked">
          <template slot="link"
                    slot-scope="props">
            <a :href="props.rowData.link"
                target="_blank">
              {{ props.rowData.link }}
            </a>
          </template>
          <template slot="action" slot-scope="props">
            <button class="btn btn-sm btn-danger"
                    type="button"
                    @click="endLiveBroadcast(props.rowData)"
                    v-show="props.rowData.active === 'yes'">
              End Live
            </button>
            <button class="btn btn-sm btn-outline-success"
                    type="button"
                    @click="setLiveBroadcast(props.rowData)"
                    v-show="!isLiveSet">
              Set Live
            </button>
            &nbsp;
            <button class="btn btn-sm btn-outline-primary"
                    type="button"
                    @click="editLiveBroadcast(props.rowData)"
                    v-show="!isLiveSet">
              Edit
            </button>
          </template>
        </vuetable>

        <!-- Pagination -->
        <div class="d-flex align-items-center justify-content-end">
          <vuetable-pagination-info ref="paginationInfo">
          </vuetable-pagination-info>
          <vuetable-pagination ref="pagination"
                              @vuetable-pagination:change-page="onChangePage"
                              :css="css.pagination">
          </vuetable-pagination>
        </div>
      </div>
    </div>
    <!-- Dialog -->
    <v-dialog/>
    <modals-container v-on:modalClose="onModalClose"/>
    <!-- Loading -->
    <loading :active.sync="loading"
             :is-full-page="true"></loading>
    <!-- <loading :active.sync="isLoading"
             :is-full-page="true"></loading> -->
  </div>
</template>

<script>
  /* eslint-disable camelcase */
  import Vue from 'vue';
  import TableStyle from '../table-bootstrap-css.js';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import moment from 'moment-timezone';

  const Loading = () => import('vue-loading-overlay');
  const Vuetable = () => import('vuetable-2/src/components/Vuetable');
  const VuetablePagination = () =>
  import('vuetable-2/src/components/VuetablePagination');
  const VuetablePaginationInfo = () =>
  import('vuetable-2/src/components/VuetablePaginationInfo');
  const LiveBroadcastDetail = () => import('./LiveBroadcastDetail.vue');
  const FilterBar = () => import('./FilterBar.vue');
  const EditLiveBroadcast = () => import('./EditLiveBroadcast.vue');

  Vue.component('live-broadcast-detail', LiveBroadcastDetail);

  export default {
    name : 'LiveBroadcast',
    data() {
      return {
        apiUrl      : process.env.VUE_APP_ROOT_API + 'api/live_broadcast/list',
        moreParams  : {},
        css         : TableStyle,
        httpOptions : {
          headers : {
            'Authorization' : `Bearer ${this.$store.state.auth.accessToken}`,
          },
        },
        fields : [
          {
            name      : 'live_broadcast_id',
            title     : 'Broadcast ID',
            sortField : 'live_broadcast_id',
            direction : 'desc',
          },
          {
            name      : 'name',
            title     : 'Name',
            sortField : 'name',
            direction : 'desc',
          },
          {
            name      : '__slot:link',
            title     : 'Link',
            sortField : 'link',
            direction : 'desc',
          },
          {
            name      : 'start_time',
            title     : 'Start Time',
            sortField : 'start_time',
          },
          {
            name      : 'created_time',
            title     : 'Date Created',
            sortField : 'created_time',
            direction : 'desc',
          },
          {
            name       : '__slot:action',
            title      : 'Actions',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
        ],
        isLiveSet : false,
        loading   : false,
        userTz    : this.$store.getters['user/user'].timezone,
      }
    },
    components : {
      // eslint-disable-next-line vue/no-unused-components
      EditLiveBroadcast,
      FilterBar,
      Loading,
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo,
    },
    methods : {

      /**
       * Transform data
       * @param data
       * @return {object}
       */
      transform(data) {
        const transformed = {};

        transformed.pagination = {
          total         : data.total,
          per_page      : data.perPage,
          current_page  : data.currentPage,
          last_page     : data.lastPage,
          next_page_url : data.nextPageUrl,
          prev_page_url : data.prevPageUrl,
          from          : data.from,
          to            : data.to,
        };

        transformed.broadcast = [];

        for (let i= 0; i < data.data.length; i++) {
          // Date conversion from UTC to User Time Zone
          const created = moment.utc(data.data[i].created_time);
          const createdConv = moment.tz(created, this.userTz)
            .format('MMM DD, YYYY, hh:mm:ss A z');
          const start = moment.utc(data.data[i].start_time);
          const startConv = (data.data[i].start_time !== '') ?
            moment.tz(start, this.userTz).format('hh:mm A z') : 'Unset';

          transformed.broadcast.push({
            live_broadcast_id : data.data[i].live_broadcast_id,
            uri               : data.data[i].uri,
            name              : data.data[i].name,
            link              : data.data[i].link,
            html_embed        : data.data[i].html_embed,
            active            : data.data[i].active,
            start_time        : startConv,
            created_time      : createdConv,
          });

          if (data.data[i].active === 'yes')
            this.isLiveSet = true;
        }

        return transformed;
      },

      /**
       * Set Pagination Info
       * @param paginationData
       */
      onPaginationData(paginationData) {
        this.$refs.pagination.setPaginationData(paginationData);
        this.$refs.paginationInfo.setPaginationData(paginationData);
      },

      /**
       * Change page
       * @param page
       */
      onChangePage(page) {
        this.$refs.vuetable.changePage(page);
      },

      /**
       * On Live Broadcast Cell Clicked
       * @param data
       */
      onCellClicked(data) {
        this.$refs.vuetable.toggleDetailRow(data.live_broadcast_id);
      },

      /**
       * On Filter Set
       * @param filterText
       */
      onFilterSet(filterText) {
        this.moreParams = {
          'filter' : filterText,
        };
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      },

      /**
       * On Filter Reset
       */
      onFilterReset() {
        this.moreParams = {};
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      },

      /**
       * Update Broadcast List
       */
      updateBroadcastList() {
        this.loading = true;
        this.$http.get('api/live_broadcast/list/update')
          .then(() => {
            this.loading = false;
            Vue.nextTick(() => this.$refs.vuetable.reload());
            this.$notify({
              group : 'notif',
              type  : 'success',
              title : 'Success',
              text  : 'Updated Live Broadcast List!',
            });
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.status === 400) {
              this.$notify({
                group : 'notif',
                type  : 'success',
                title : 'Success',
                text  : 'Live Broadcast List is up to date!',
              });
            } else {
              this.$notify({
                group : 'notif',
                type  : 'error',
                title : 'Failed',
                text  : 'Oops! Something went wrong!',
              });
            }
          }
          );
      },

      /**
       * Set Live Broadcast
       * @param broadcast
       */
      setLiveBroadcast(broadcast) {
        this.$modal.show('dialog', {
          title : 'Set Live',
          text  : 'Are you sure you want to set ' + broadcast.name +
            ' as live?',
          buttons : [ {
            title   : 'Yes',
            handler : () => {
              this.$http.post('api/live_broadcast/set', {
                liveBroadcastId : broadcast.live_broadcast_id,
                action          : 'set',
              }).then(() => {
                this.isLiveSet = true;
                this.$modal.hide('dialog');
                Vue.nextTick(() => this.$refs.vuetable.reload());
                this.$notify({
                  group : 'notif',
                  type  : 'success',
                  title : 'Success!',
                  text  : 'Set ' + broadcast.name + ' as live!',
                });
              }).catch(() => {
                this.$notify({
                  group : 'notif',
                  type  : 'error',
                  title : 'Failed!',
                  text  : 'Oops! Something went wrong!',
                });
              });
            },
          }, {
            title : 'No',
          } ],
        });
      },

      /**
       * End Live Broadcast
       * @param broadcast
       */
      endLiveBroadcast(broadcast) {
        this.$modal.show('dialog', {
          title   : 'End Live',
          text    : 'Are you sure you want to end ' + broadcast.name + '?',
          buttons : [ {
            title   : 'Yes',
            handler : () => {
              this.$http.post('api/live_broadcast/unset', {
                liveBroadcastId : broadcast.live_broadcast_id,
                action          : 'unset',
              }).then(() => {
                this.isLiveSet = false;
                this.$modal.hide('dialog');
                Vue.nextTick(() => this.$refs.vuetable.reload());
                this.$notify({
                  group : 'notif',
                  type  : 'success',
                  title : 'Success!',
                  text  : 'Ended ' + broadcast.name + ' live broadcast!',
                });
              }).catch(() => {
                this.$notify({
                  group : 'notif',
                  type  : 'error',
                  title : 'Failed!',
                  text  : 'Oops! Something went wrong!',
                });
              });
            },
          }, {
            title : 'No',
          } ],
        });
      },

      /**
       * Edit Live Broadcast
       * @param broadcast
       */
      editLiveBroadcast(broadcast) {
        this.$modal.show(
          EditLiveBroadcast,
          { data : broadcast },
          { height : 'auto', adaptive : true });
      },

      /**
       * Close modal
       */
      onModalClose() {
        this.$refs.vuetable.reload();
      },

      /**
       * Show Loader
       */
      showLoader() {
        this.loading = true;
      },

      /**
       * hide Loader
       */
      hideLoader() {
        this.loading = false;
      },
    },
    mounted() {
      // Update live_broadcast db table
      this.updateBroadcastList();

      this.$events.$on('filter-set', eventData => this.onFilterSet(eventData));
      this.$events.$on('filter-reset', () => this.onFilterReset());
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/broadcast/live-broadcast";
</style>
